<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Expands Leadership Team with Appointments of Three Key Executives
      </div>
      <div class="time">
        Mar 28, 2023
      </div>
      <div class="content">
        
        <p>
          Cambridge, Mass. – March 28, 2023 – GV20 Therapeutics, a biotechnology company using cutting-edge genomics and artificial intelligence approaches to discover next-generation cancer therapeutics, announced three key executive appointments as it transitions into a clinical-stage company. Xingfeng Bao, Ph.D., was appointed Vice President and Head of Biology; Karim Benhadji, M.D., was named Chief Medical Officer; and Jie Chen, M.D., M.S., was named Chief Technical Operations and Quality Officer.
        </p>
        <p>
          “We are thrilled to welcome Xingfeng, Karim, and Jie to our team,” said Shirley Liu, Co-founder and CEO of GV20. “They have decades of experience leading drug research and development organizations and building innovative pipelines.  With the addition of their expertise and leadership, we are well-positioned to execute the clinical program of GV20-0251, our lead antibody against IGSF8, and to accelerate our discovery of additional drug candidates with our integrated STEAD (Simultaneous Target Evaluation and Antibody Discovery) platform. We are even more confident in GV20’s ability to bring next-generation therapies to cancer patients.”
        </p>
        <p>
          <div class="font-weight-bold">
            ABOUT XINGFENG BAO, PH.D.
          </div>
          Dr. Xingfeng Bao serves as the Vice President and Head of Biology for GV20 Therapeutics. He has 20 years of research and development experience in immunotherapy and targeted oncology drugs with a particular focus in early discovery to early clinical development. During his career in industry, he has led the discovery of six development candidates and three IND filings in small molecules and biologics. Prior to joining GV20, Dr. Bao was Executive Director, Head of Cancer Immunology and Pharmacology at H3biomedicine, where he led the company’s discovery and translational biology group focused on cancer immunotherapy and antibody-drug conjugates. In his earlier career, he also held roles of increasing responsibility from Project Lead to Head of Cancer Immunotherapy Discovery at Eisai’s Oncology Business Unit.
        </p>
        <p>
          Dr. Bao earned his doctorate in 2002 and completed his postdoctoral fellowship at Sanford-Burnham-Prebys Medical Discovery Institute, La Jolla, California.
        </p>
        <p>
          <div class="font-weight-bold">
            ABOUT KARIM BENHADJI, M.D.
          </div>
          Dr. Karim Benhadji serves as the Chief Medical Officer for GV20 Therapeutics. He has over 17 years of experience in biopharmaceutical oncology drug development. He previously served as Senior Vice President, Head of Clinical Development and Operations at Taiho Oncology where he oversaw the development of early and late phase portfolio assets including regulatory fillings leading to the FDA approval of Lytgobi®. Prior to joining Taiho Oncology, Dr. Benhadji spent over 13 years with Eli Lilly and Company in oncology development roles of increasing responsibility. He was most recently Vice President, Early Phase Oncology and oversaw several immuno-oncology programs. 
        </p>
        <p>
          Dr. Benhadji received his M.D. from the University of Oran. He completed medical oncology fellowships at the Universities of Oran and Paris 7 and holds a Master of Science in Pharmacology and Therapeutics in Oncology from the University of Paris 11.
        </p>
        <p>
          <div class="font-weight-bold">
            ABOUT JIE CHEN, M.D., M.S.
          </div>
          Dr. Jie Chen serves as the Chief Technical Operations & Quality Officer for GV20 Therapeutics. She brings over 25 years of biopharmaceutical industry experience leading biologics CMC development from DNA to IND to BLA and commercialization, with a track record of numerous successful IND and BLA filings. She previously served as Vice President of CMC Management and then Senior Vice President/Site Head of US Manufacturing and Operations at WuXi Biologics.  She was responsible for managing client CMC projects and leading the startup efforts of Wuxi’s new US sites from design, construction, team hiring/building, and lab operations to manufacturing cGMP release and successful cGMP manufacturing production. Prior to joining WuXi Biologics, Dr. Chen spent over 18 years in biopharmaceutical companies in the Greater Boston area including Dyax and Shire, where she led protein sciences, process development, and CMC functions, as well as managed internal teams and CDMOs. 
        </p>
        <p>
          Dr. Chen received her M.D. from Medical School of Xi’an Jiao Tong University and practiced as an oncologist in China. She completed her post-doctoral training at the University of Pennsylvania and received her Master of Science in Physiology and Neurobiology from Rutgers University.
        </p>
        <p>
          <div class="font-weight-bold">
            ABOUT GV20 THERAPEUTICS
          </div>
          GV20 Therapeutics is a biotechnology company with sites in Cambridge, Massachusetts and Shanghai, China. GV20 uses high-throughput functional genomics and artificial intelligence to identify novel cancer drug targets and antibody therapeutics. The company’s pipeline includes GV20-0251, a first-in-class, fully human, Fc-attenuated IgG1 monoclonal antibody against a novel immune checkpoint IGSF8, which is currently being evaluated in a Phase 1 clinical study (ClinicalTrials.gov Identifier: NCT05669430). “GV20” references one of the most important acupoints for the human body, symbolizing the company’s mission to find and exploit vulnerabilities in cancer biology. 
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://mobile.gv20tx.com/">https://gv20tx.com/</a> and follow the company on <a style="color:#2d8cf0;" href="https://www.linkedin.com/company/gv20tx/">LinkedIn</a>.
        </p>

        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>

      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>

